import React from 'react'
import GooglePayButton from '@google-pay/button-react';

const GPayPaymentBtn = () => {
    return (
        <div className="App">
          <GooglePayButton
            buttonType="subscribe"
            environment="TEST"
            paymentRequest={{
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods: [
                {
                  type: 'CARD',
                  parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['MASTERCARD', 'VISA'],
                  },
                  tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                      gateway: 'example',
                      gatewayMerchantId: 'BCR2DN4TSOWPNRLP',
                    },
                  },
                },
              ],
              merchantInfo: {
                merchantId: 'BCR2DN4TSOWPNRLP',
                merchantName: 'HowIsMarket',
              },
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: '1',
                currencyCode: 'INR',
                countryCode: 'IN',
              },
            }}
            onLoadPaymentData={paymentRequest => {
              console.log('load payment data', paymentRequest);
            }}
          />
        </div>
      );
}

export default GPayPaymentBtn