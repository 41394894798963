import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/body/Home";
import UserLogIn from './components/user/UserLogIn';
import Protected from './components/user/Protected';
import UserRegistration from './components/user/UserRegistration';
import UserProfile from './components/user/UserProfile';
import axios from 'axios';
import UserLogOut from './components/user/UserLogOut';
import UserForgetPassword from './components/user/UserForgetPassword';
import UserResetPassword from './components/user/UserResetPassword';
import Pricing from './components/header/Pricing';
import { API_URL } from './constants';
import Payment from './components/payment/Payment';

function App() {

  const [currentUser, setCurrentUser] =  useState('');

  const fetchCurrentUser = async () =>{

    try {
      const token = localStorage.getItem("token")
      const response = await axios.get(`${API_URL}/profile`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    setCurrentUser(response.data.logged_in_as);

    } catch (error) {
      setCurrentUser(null)
    }
    
  }


  useEffect( () =>{
    fetchCurrentUser();
  }, []);

  return (
    <div className="App">
      <Router>

        <Header currentUser={currentUser}/>

        <Routes>
          <Route path="/" Component={Home} />
          <Route path='/pricing' element={<Pricing currentUser={currentUser} />} />
          <Route path="/user/logIn" Component={UserLogIn} />
          <Route path="/user/UserRegistration" Component={UserRegistration} />
          <Route path="/user/forgotPassword" Component={UserForgetPassword} />

          {/* another way of passing props in Route */}
          {/* <Route path="/user/UserRegistration"><UserRegistration props="props" /></Route> */}

          <Route path="/user/profile" element={<UserProfile currentUser = {currentUser}/>} />
          <Route path="/protected" Component={Protected} />
          <Route path="/logout" Component={UserLogOut} />
          <Route path='/user/resetPassword' Component={UserResetPassword} />
          <Route path='/user/payment' Component={Payment} />
        </Routes>

        <Footer />
      </Router>
      
    </div>
  );
  
}

export default App;